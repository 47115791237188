//es6 polyfills required for IE11
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import reportWebVitals from './reportWebVitals';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './services/store';
import App from './components/App';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { AppProvider } from '@reassure/ui/core';
import theme from './theme';
import PageLoading from './components/page-loading/PageLoading';

Amplify.configure(awsconfig);

export const store = configureStore();

const customCss = `
    body {
        background-color: ${theme.colors.background2};
        font-family: "Open Sans",sans-serif;
        color: ${theme.colors.primaryText};
    } 
`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <AppProvider head={{ customCss }} theme={theme}>
                <BrowserRouter>
                    <Suspense fallback={<PageLoading />}>
                        <App />
                    </Suspense>
                </BrowserRouter>
            </AppProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(process.env.REACT_APP_LOG_WEB_VITALS === 'true' ? console.log : null);
